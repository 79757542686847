@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #acacac;
  font-style: italic;
}

#container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#main-content {
  flex: 1;
}

.section-dropdown button {
  color: #0095ff;
  background: white;
  border-color: #d1d1d1;
}

.btn-secondary {
  border-color: #d1d1d1;
  background: white;
}

.video-card {
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 16px rgba(0, 0, 0, 0.03);
}

.footer {
  padding: 75px 0px 60px 40px;
}

.btn-application-platform {
  background: #ffffff;
}
.btn-application-platform-apple {
  background: #717171;
}

.platform-btn {
  border: 1px solid #d1d1d1;
}

/*.platform-btn:not(:active):hover {
  background: #f7f7f7;
}

.platform-btn:hover {
  fill: #969696;
}*/

.platform-btn:active:hover {
  background: transparent;
}

.platform-link:hover {
  background: #f7f7f7;
}

.btn-application-platform-android {
  background: #3ddb85;
}
.btn-application-platform-javascript {
  background: #ffe61f;
}

.small-dot {
  height: 10px;
  width: 10px;
  border-color: #717171;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  display: inline-block;
}

.small-dot-active {
  height: 10px;
  width: 10px;
  border-color: #0095ff;
  background: #0095ff;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  display: inline-block;
}
.dot {
  height: 20px;
  width: 20px;
  border-color: #717171;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  display: inline-block;
}

.dot-active {
  height: 20px;
  width: 20px;
  border-color: #0095ff;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  display: inline-block;
}

.video-api-label {
  color: #4d4d4d;
  font-weight: bold;
}

.video-api-key-value {
  background: #f7f7f7;
  border-radius: 6px;
  color: #4d4d4d;
}

.video-api-new-webhook-input {
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid #d1d1d1;
}

.icon-bg {
  height: 40px;
  width: 40px;
}

.copy-icon {
  color: #acacac;
}

.copy-icon:hover {
  cursor: pointer;
}

.gray-circle-btn {
  padding: 6px 7px;
  background: none;
  border: none;
  border-radius: 50%;
}
.gray-circle-btn:hover {
  background: #f7f7f7;
}
.gray-circle-btn:focus {
  box-shadow: none;
  background: #f7f7f7;
}

.info-icon {
  stroke-width: 1;
  fill: #acacac;
}

.info-icon:hover {
  cursor: pointer;
}

.close-icon:hover {
  cursor: pointer;
}

.trash-icon:hover {
  cursor: pointer;
}

.video-api-link {
  border: none;
  padding: 0px;
  margin-top: -0.2em;
}
.video-api-link:focus {
  box-shadow: none;
}
/*tooltips*/
.license-tooltip > .tooltip-inner {
  max-width: 100%;
  padding: 18px 24px 5px 24px;
}
.video-api-tooltip > .tooltip-inner {
  max-width: 280px;
  padding: 14px 16px 2px 16px;
}
.tooltip-license-details {
  color: #d1d1d1;
}

/*ssai page*/
.btn-pale-grey,
.btn-veeplay-blue,
.btn-ssai-docs,
.btn-edit-stream,
.btn-reload-content,
.btn-links,
.btn-logs,
.btn-analytics,
.input-field,
.btn-sched-call {
  background: #f7f7f7;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: #717171;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
.btn-veeplay-blue,
.btn-sched-call {
  background: #0095ff;
  color: white;
}
.btn-ssai-docs {
  border: 1px solid #717171;
  background-color: white;
}
.btn-edit-stream,
.btn-reload-content,
.btn-links,
.btn-logs,
.btn-analytics {
  width: 123px;
  height: 38px;
  font-size: 12px;
  line-height: 18px;
  margin-top: 0px;
}
.hls-label,
.dash-label,
.client-side-tracking-label {
  font-size: 0.8rem;
  font-weight: 600;
}
.btn-reload-content {
  width: 144px;
  margin-left: 24px;
}
.btn-links,
.btn-logs,
.btn-analytics {
  width: 96px;
  height: 32px;
}
.btn-pale-grey:hover,
.btn-edit-stream:hover,
.btn-reload-content:hover,
.btn-links:hover,
.btn-logs:hover,
.btn-analytics:hover {
  background: #f0f0f0;
  color: #717171;
}
.btn-veeplay-blue:hover,
.btn-sched-call:hover {
  background: #0086e6;
}
.btn-ssai-docs:hover {
  background: #969696;
  color: #ffffff;
  border: none;
}
.btn-edit-stream:focus,
.btn-reload-content:focus,
.btn-links:focus,
.btn-logs:focus,
.btn-analytics:focus {
  background: #969696;
  color: #ffffff;
  box-shadow: none;
}
.input-field {
  width: 442px;
  margin-top: 0px;
  border: 1px solid #d1d1d1;
  padding-left: 12px;
  background: white;
}
.input-field:focus {
  outline: none !important;
  border: 1px solid #85ccff;
}
.input-field:focus::placeholder {
  color: transparent;
}
.input-field:placeholder-shown {
  font-style: italic;
  font-size: 15px;
  line-height: 24px;
  color: #acacac;
  padding-left: 12px;
  font-weight: 400;
}
.divider,
.divider-new,
.divider-logs {
  height: 1px;
  background: #d1d1d1;
  margin-top: 56px;
}
.divider-new {
  margin-top: 48px;
}
.divider-logs {
  margin-top: 0px;
  width: 440px;
}
.url-label,
.vast-tag-label {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}
.list-label,
.stats-7-days,
.analytics-txt,
.analytics-val,
.data-update-hourly,
.list-item-details,
.ssai-details,
.timestamp {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #0095ff;
}
.timestamp {
  font-weight: 400;
  color: #717171;
}
.ssai-details {
  color: black;
}
.stats-7-days {
  font-weight: 600;
  color: #1d1d1d;
}
.analytics-txt {
  font-weight: 400;
  color: #1d1d1d;
  float: left;
  margin-top: 0.1em;
}
.analytics-val {
  font-weight: 600;
  float: left;
  margin-top: 0.1em;
}
.data-update-hourly {
  font-weight: 400;
  color: #969696;
  float: right;
  margin-top: 0.1em;
}
.list-item-details {
  padding-left: 20px;
  overflow: hidden;
  color: #4d4d4d;
  width: 450px;
  font-weight: 400;
}
.blue-bullet {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-top: 0.5em;
  background: #0095ff;
  margin-left: 2px;
}
.div-ssai-links {
  margin-top: 40px;
}
.div-hls,
.div-dash,
.div-cstl {
  margin-left: 0px;
}
.div-links {
  background: #f7f7f7;
  border-radius: 6px;
  overflow: hidden;
  padding: 5px 2px 5px 10px;
}
.div-text {
  font-size: 14px;
  line-height: 22px;
  color: #4d4d4d;
  white-space: nowrap;
  overflow: auto;
}
.copy-icon {
  margin-top: -0.25em;
  margin-left: 15px;
}
.div-logs {
  height: 382px;
  margin-top: 40px;
  border-top: 2px solid #d1d1d1;
  border-bottom: 2px solid #d1d1d1;
}
.log-list {
  height: 316px;
  margin-top: 31px;
  overflow-y: auto;
}
.log-list::-webkit-scrollbar {
  width: 4px;
  height: auto;
}
.log-list::-webkit-scrollbar-thumb {
  background: #f0f0f0;
  border-radius: 2px;
}
.text-with-icon {
  width: 271px;
  height: 24px;
  float: left;
}
.text-only {
  height: 22px;
  font-size: 14px;
  line-height: 20px;
  float: left;
}
.btn-ssai-modal,
.btn-close-ssai-modal {
  width: 24px;
  height: 24px;
  border: none;
  float: right;
  align-items: center;
  display: inline-flex;
}
.btn-ssai-modal:hover,
.btn-close-ssai-modal:hover {
  background-color: white;
}
.btn-ssai-modal:focus,
.btn-close-ssai-modal:focus {
  box-shadow: none;
  background: white;
}
.modal-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  float: left;
}
.div-ssai-analytics,
.analytics-list {
  height: 308px;
  margin-top: 40px;
}
.analytics-list,
.analytics-list-1,
.analytics-list-2 {
  height: 264px;
  margin-top: 24px;
}
.analytics-list-1,
.analytics-list-2,
.analytics-list-item {
  width: 213px;
  margin-top: 0px;
}
.analytics-list-1 {
  float: left;
}
.analytics-list-2 {
  float: right;
}
.analytics-list-item {
  height: 24px;
  margin-bottom: 24px;
}
.div-value {
  display: flex;
  float: right;
  height: 24px;
}

.btn-usage {
  background: #969696;
  color: #ffffff;
}

.registered-apps-border {
  border: 1px solid #d1d1d1;
}

.input-text-field {
  border-radius: 6px;
  border: 1px solid #d7d7d7;
  padding: 5px;
}

.input-text-field:focus {
  outline: none;
}

.saved-webhooks-modal {
  border-radius: 12px;
  padding: 1.5rem;
}

.saved-webhooks-header {
  padding-left: 0px;
  padding-bottom: 2rem;
}

.copy-icon-api {
  margin: 0;
}

.onboarding-form-footer {
  border: 0 none;
}

.onboarding-form-modal {
  border-radius: 12px;
}

/*transcoding page*/
.heading-h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
}
.heading-h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}
.license-label,
.videos-page-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
}
.videos-page-label {
  font-weight: normal;
  color: #717171;
  margin-right: 32px;
}
.btn-video-asset {
  background: #0095ff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.btn-video-asset:hover {
  background: #0086e6;
}

/*Livestream page*/
.btn-create-livestream,
.btn-cancel,
.btn-done {
  background: #0095ff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: none;
  padding: 10px 16px;
}
.btn-cancel {
  background: #f7f7f7;
  color: #717171;
  padding: 10px 27.5px;
  margin-right: 32px;
  margin-bottom: 10px;
}
.btn-create-livestream:hover {
  background: #0086e6;
}
.btn-done {
  padding: 10px 32px;
  margin-bottom: 10px;
}
.btn-done:disabled {
  background: #f4fbff;
  color: #85ccff;
}
.btn-done:disabled:hover {
  background: #f4fbff;
  color: #85ccff;
}
.btn-create-livestream:hover,
.btn-done:hover {
  background: #0086e6;
}
.btn-cancel:hover {
  background: #f0f0f0;
  color: #717171;
}
.no-livestream {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: #f7f7f7;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  color: #717171;
  padding: 30px;
}
.heading-h4 {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
}
.stream-name-input,
.other-service-input,
.other-service-input-disabled,
.stream-url-input,
.other-input-onboarding {
  width: 348px;
  height: 40px;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 20px;
}
.other-service-input,
.other-service-input-disabled,
.stream-url-input,
.other-input-onboarding {
  width: 100%;
}
.other-service-input-disabled {
  border: none;
}
.stream-name-input:focus,
.other-service-input:focus,
.stream-url-input:focus,
.other-input-onboarding:focus {
  outline: none !important;
  border: 1px solid #85ccff;
}
.stream-name-input:focus::placeholder,
.other-service-input:focus::placeholder,
.stream-url-input:focus::placeholder,
.other-input-onboarding:focus::placeholder {
  color: transparent;
}
.stream-name-input:placeholder-shown,
.other-service-input:placeholder-shown,
.stream-url-input:focus:placeholder-shown,
.other-input-onboarding:placeholder-shown {
  font-size: 15px;
  line-height: 24px;
  color: #acacac;
  padding-left: 12px;
  font-weight: normal;
}
.re-stream-txt,
.add-service-modal-label {
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
}
.add-service-modal-label {
  color: #717171;
}
.blue-asterisk {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #0095ff;
}
.btn-add-service {
  border: 1px solid #0095ff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 6px 16px;
}
.btn-add-service:hover {
  background: #0095ff;
}
.modal-add-service {
  border-radius: 12px;
}

/*livestream card*/
.stream-name-card {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1d1d1d;
}
.livestream-card-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #4d4d4d;
  width: 88px;
}
.btn-edit-service {
  border: 1px solid #717171;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #717171;
  padding: 6px 16px;
  margin-right: 30px;
}
.livestream-card-input-1,
.livestream-card-input-2 {
  width: 68%;
  height: 34px;
  background: #f7f7f7;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #4d4d4d;
  border: none;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: -1.4em;
}
.livestream-card-input-2 {
  margin-top: -1.2em;
}

.btn-group-item:hover {
  background: #f7f7f7;
}

.btn-secondary-outline {
  color: #717171;
  background: #ffffff;
  border: 1px solid #717171;
  border-radius: 6px;
}

.btn-secondary-hover {
  color: #717171;
  background: #ffffff;
  border: 1px solid #717171;
  border-radius: 6px;
}

.btn-secondary-hover:hover {
  background: #f0f0f0;
  color: #717171;
}

.btn-secondary-hover:focus {
  background: #969696;
  color: #fff;
}

.btn-secondary-hover:active {
  background: #969696;
  color: #fff;
}

.btn-secondary-hover:disabled {
  background: #f7f7f7;
  color: #acacac;
  border: none;
}

table.api-table td {
  padding-top: 32px;
}

table.api-table th {
  padding-bottom: 32px;
}

.account-settings-form-title {
  font-weight: 700;
  font-size: 14px;
  color: #0095ff;
  padding-bottom: 25px;
  padding-top: 40px;
}

.account-settings-form {
  padding-bottom: 13px;
}

.account-settings-helper-text {
  padding-top: 24px;
  padding-bottom: 24px;
}

.btn-account-settings {
  width: 120px;
  height: 40px;
}

.icon:hover {
  cursor: pointer;
}

.livestream-item-card {
  padding: 1rem 2rem;
}

.livestream-item-header {
  background: transparent;
  padding: 0.75rem 0;
}

.livestream-item-body {
  padding: 1.25rem 0;
}

.livestream-item-footer {
  background: transparent;
  padding: 0.75rem 0;
}

.livestream-item-btn-start {
  background: #00c95c;
  color: #fff;
}

.livestream-item-btn-start:hover {
  background: #4caf50;
  color: #fff;
}

.btn-disabled {
  background: #f7f7f7;
  color: #acacac;
  border: none;
}

.btn-disabled:hover {
  color: #acacac;
}

.livestream-item-btn-stop {
  border: 1px solid #f64113;
  color: #f64113;
}

.livestream-item-btn-stop:hover {
  border: 1px solid #f64113;
  background: #f64113;
  color: #fff;
}

.livestream-btn-cancel {
  margin-right: 0.5rem;
  width: auto;
  padding: 0.5rem 1rem;
  background: #f7f7f7;
  color: #717171;
  border-radius: 6px;
}

.livestream-btn-primary {
  margin-left: 0.5rem;
  padding: 0.5rem 1.5rem;
  background: #0095ff;
  color: #fff;
}

.livestream-btn-primary:hover {
  color: #f7f7f7;
  background: #2b78fb;
}

.livestream-input-output-urls-modal {
  padding: 2rem;
}

.grey-text {
  color: #717171;
}

.btn-reg-app:hover {
  background: #f0f0f0;
  color: #717171;
}

.video-img-container {
  position: relative;
}

.selected-nav {
  color: #0095ff !important;
  background: #f4fbff;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active {
  background: #969696;
}

.nav-usage:hover {
  color: #0095ff;
  text-decoration: none;
}

.mock-dropdown-and-date-range-picker,
.mock-dropdown-container {
  border-radius: 4px;
  height: 39px;
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-top: -1px;
}
.mock-dropdown {
  height: 37px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}
.dropdown-input {
  text-overflow: ellipsis;
  width: calc(100% - 34px);
  border: none;
  background: transparent;
  color: #262626;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dropdown-input::selection {
  background: transparent;
}
.dropdown-input::-moz-selection {
  background: transparent;
}
.hi-chevron-icon {
  margin-top: 7px;
  margin-left: calc(100% - 29px);
}
.div-date-range-picker,
.div-dropdown-options {
  border: 1px solid #d9d9d9;
  z-index: 999999;
  position: absolute;
  margin-top: 5px;
  margin-left: -1.7px;
  border-radius: 4px;
  padding-top: 20px;
  background: white;
}
.div-dropdown-options {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 6px;
  width: calc(100% - 28px);
}
.div-option {
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: context-menu;
}
.option-label::selection {
  background: transparent;
}
.option-label::-moz-selection {
  background: transparent;
}
